

















































































































































































































































































.dialogPaper {
  .el-form {
     display:flex;
      .el-form-item {
        display:flex
    }
  }
 
}
  
